import React, { useState, useEffect, useMemo } from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../../utils/LinkResolver";
import { Layout } from "../../components/Layout";
import { RichText } from "prismic-reactjs";
import Seo from "../../components/Seo";
import { MediaGallery } from "../../slices/MediaGallery/MediaGallery";
import { Card } from "../../components/Card/Card.js";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";

import "./prismicProject.scss";

const BASE_URL = "/".concat(
  process.env.FUNCTIONS,
  "/",
  process.env.FUNCTION_PROJECT_STATS,
  "/"
);
const GET_URL = BASE_URL.concat("all");
const UPDATE_URL = BASE_URL.concat("update");

const ProjectTemplate = ({ location, data }) => {
  const windowGlobal = typeof window !== "undefined" && window;
  const categories = data.allPrismicCategory.nodes.map((category) => {
    return category.data.title;
  });
  categories.push("Year");
  categories.push("Likes");
  categories.push("Shares");

  const isJoker =
    (location.state && location.state.hasOwnProperty("isJoker")) ||
    data?.prismicProject?.nodes?.isJoker
      ? location.state.isJoker || data?.prismicProject?.nodes?.isJoker
      : false;
  const idx =
    location.state && location.state.hasOwnProperty("idx")
      ? location.state.idx
      : false;
  const sortedArray = useMemo(
    () =>
      location.state && location.state.hasOwnProperty("sortedArray")
        ? location.state.sortedArray
        : [...data.renderArray.nodes, data.jokerProject],
    [data.jokerProject, data.renderArray.nodes, location.state]
  );

  const lastItem = idx === sortedArray.length - 2;

  const [renderArray, setRenderArray] = useState(sortedArray.slice(0, 4));
  const [nextIdx, setNextIdx] = useState(0);
  const [projectStats, setProjectStats] = useState(false);

  useEffect(() => {
    if (!projectStats) {
      const fetchData = async () => {
        const projectStatsStream = await fetch(GET_URL);
        const tmpProjectStats = await projectStatsStream.json();

        setProjectStats(tmpProjectStats);
      };

      fetchData();
    }
  }, [projectStats, setProjectStats]);

  useEffect(() => {
    if (sortedArray) {
      if (idx + 5 < sortedArray.length) {
        setRenderArray(sortedArray.slice(idx + 1, idx + 5));
        setNextIdx(idx + 1);
      } else if (idx + 1 < sortedArray.length - 1) {
        setRenderArray(sortedArray.slice(idx + 1, sortedArray.length));
        setNextIdx(idx + 1);
      } else if (idx + 1 === sortedArray.length - 1) {
        setRenderArray(sortedArray.slice(sortedArray.length - 1));
        setNextIdx(sortedArray.length - 1);
      } else {
        setRenderArray(sortedArray.slice(0, 4));
        setNextIdx(0);
      }
    }
  }, [idx, sortedArray]);

  const row = !isNaN(idx) ? (idx - (idx % 4)) / 4 : false;
  const col = !isNaN(idx) ? idx % 4 : false;

  if (!data) return null;

  const project = data.prismicProject;
  const gallerySlice = project.data.body1[0];
  const url = location.pathname;
  const socialCards = data.prismicProject?.data?.body6;

  return (
    <Layout
      backTo={isJoker ? "/#joker" : false}
      backLinkState={
        !isNaN(row) && !isNaN(col) ? { row: row, col: col } : false
      }
      isProject={true}
    >
      <Seo
        title={project.data?.meta_title || project.data.title.text}
        description={project.data?.meta_description || ""}
        pageUrl={url}
        socialCards={socialCards}
      />
      <main className={`page-project ${isJoker ? "page-project-joker" : ""}`}>
        <section className="typo__section">
          <div className="typo__wrapper">
            <h1 className="typo__title">
              {project.data.title.text || "Project Title"}
            </h1>
            <span className="typo__agency-location">
              {project.data.agency || "agency name, location."}
            </span>
            <p className="typo__text">
              {RichText.render(project.data.description.raw)}
            </p>
          </div>
        </section>

        {gallerySlice ? (
          <section className="page-project__video-gallery">
            <MediaGallery slice={gallerySlice} noSection={true} />
          </section>
        ) : null}

        <section
          className={`page-project__card-stats ${
            !project.data.show_about && "padding"
          }`}
        >
          <p className="page-project__card-stats--title">Card Stats</p>
          <ul className="page-project__card-stats--categories">
            {!isJoker &&
              categories.map((title, idx) => {
                const rating = project.data[`category${idx + 1}`];
                if (idx < data.allPrismicCategory.nodes.length) {
                  return (
                    <li
                      key={`page-project-category-${idx + 1}`}
                      className="item"
                    >
                      <span>{title}</span>
                      <span>{rating || 0}</span>
                    </li>
                  );
                }
                return null;
              })}
            {isJoker && (
              <li className="item joker">This is mad you win this round</li>
            )}
          </ul>
        </section>

        {project.data.show_about ? (
          <section className="page-project__about">
            <p className="page-project__about--title">Behind The Work</p>
            {project.data.behind_the_work_media_type &&
            project.data?.behind_the_work_media?.url ? (
              <video
                className="page-project__about--media"
                controls={true}
                src={project.data.behind_the_work_media.url}
              >
                <track default kind="captions"></track>
              </video>
            ) : (
              <img
                className="page-project__about--media"
                src={project.data.behind_the_work_media.url}
              />
            )}
            <p className="page-project__about--text">
              {RichText.asText(project.data.about_text.raw)}
            </p>
          </section>
        ) : null}

        <section className="page-project__other-projects fullwidth">
          <p className="page-project__other-projects--title">
            Discover More Cards
          </p>
          <div className="section">
            <div
              className={`page-project__other-projects--swiper-wrapper ${
                lastItem ? "last-item" : ""
              }`}
            >
              <Swiper
                spaceBetween={30}
                slidesPerView={lastItem ? 1 : "auto"}
                autoHeight={true}
                centeredSlides={true}
                initialSlide={0}
                onSlideChange={(swiper) => {
                  swiper.slides[swiper.previousIndex]
                    .querySelector(".component-card")
                    .classList.remove("active-card");
                }}
                breakpoints={{
                  768: {
                    centeredSlides: lastItem ? true : false,
                    spaceBetween: 48,
                  },
                }}
              >
                {renderArray &&
                  renderArray.map((project, idx) => {
                    const isJoker = project.data?.isjoker || false;

                    const description = project.data?.description.raw
                      ? RichText.asText(project.data.description.raw)
                      : "";

                    const projectCategories = [];

                    categories.forEach((title, idx) => {
                      const rating = project.data[`category${idx + 1}`];
                      if (idx < data.allPrismicCategory.nodes.length) {
                        projectCategories.push({
                          title: title,
                          rating: rating,
                        });
                      }
                    });

                    const attr = project.data
                      ? {
                          uniqueId: project.uid,
                          title: project.data.title,
                          client: project.data.client,
                          category: project.data.category,
                          year: project.data.year_of_creation,
                          image: project.data.card_image,
                          text:
                            description.length > 120
                              ? description.substring(0, 120).concat("...")
                              : description,
                          linkUrl: "/projects/".concat(project.uid),
                          categories: projectCategories,
                          description: project.data?.description,
                          year_of_creation: project.data.year_of_creation,
                          card_image: project.data.card_image,
                          url: project.url,
                          sortedArray: sortedArray,
                          idx: nextIdx + idx,
                          stats:
                            projectStats &&
                            projectStats.hasOwnProperty(project.uid)
                              ? projectStats[project.uid]
                              : { likes: false, shares: false },
                          callback: (obj) => {
                            const tmpProjectStats = { ...projectStats };
                            tmpProjectStats[project.uid] = obj;

                            const updateData = async (tmpJson) => {
                              await fetch(UPDATE_URL, {
                                method: "POST",
                                body: JSON.stringify({
                                  batchUpdate: tmpJson,
                                }),
                              });
                            };
                            updateData({ ...tmpProjectStats });

                            setProjectStats(tmpProjectStats);
                          },
                          allowEdit:
                            windowGlobal &&
                            localStorage.getItem(project.uid) === null &&
                            projectStats !== false
                              ? true
                              : false,
                        }
                      : {};

                    return (
                      <SwiperSlide>
                        <Card
                          isProjectPage={true}
                          delay={(renderArray.length - (idx + 1)) * 100}
                          cardType={isJoker ? "Joker" : "Project"}
                          {...attr}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export const query = graphql`
  query ProjectQuery($id: String) {
    jokerProject: prismicProject(data: { isjoker: { eq: true } }) {
      data {
        category1
        category2
        category3
        category4
        category5
        year_of_creation
        client
        category
        card_image {
          url
          alt
        }
        title {
          text
        }
        isjoker
        description {
          raw
        }
        joker_text {
          raw
        }
        joker_title
        button_text
        button_target {
          target
          url
        }
        background_image {
          url
        }
      }
      url
      uid
    }
    renderArray: allPrismicProject(
      filter: { data: { isjoker: { ne: true } } }
    ) {
      nodes {
        uid
        url
        data {
          category1
          category2
          category4
          category3
          category5
          year_of_creation
          client
          category
          isjoker
          card_image {
            url
            alt
          }
          title {
            text
          }
          description {
            raw
          }
        }
      }
    }
    prismicProject(id: { eq: $id }) {
      uid
      data {
        about_text {
          raw
        }
        category1
        category2
        category3
        category4
        category5
        description {
          raw
        }
        isjoker
        title {
          text
        }
        agency
        show_about
        year_of_creation
        behind_the_work_media_type
        behind_the_work_media {
          url
        }
        body1 {
          ... on PrismicSliceType {
            slice_type
          }
          ...PrismicProjectDataMediaGallery
        }
        meta_description
        meta_title
        body6 {
          ... on PrismicProjectDataBody6GeneralCard {
            slice_type
            primary {
              title {
                text
              }
              image {
                url
              }
              description {
                text
              }
            }
          }
          ... on PrismicProjectDataBody6TwitterCard {
            slice_type
            primary {
              title {
                text
              }
              image {
                url
              }
              description {
                text
              }
            }
          }
        }
      }
    }
    allPrismicCategory(sort: { fields: data___display_order, order: ASC }) {
      nodes {
        data {
          display_order
          title
        }
      }
    }
  }
`;

export default withPrismicPreview(ProjectTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
