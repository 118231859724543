import React, { useState, useRef, useEffect, useCallback } from "react";
import { graphql } from "gatsby";
import playButton from "../../images/play_button_white.png";
import "./mediaGallery.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";

SwiperCore.use([Pagination]);

export const MediaGallery = ({ slice, noSection }) => {
  const items = slice.items || [];
  const sliderRef = useRef();
  const [lastIndex, setLastIndex] = useState(0);
  const swiperRef = useRef();

  return (
    <WrapperElement className="slice-media-gallery" noSection={noSection}>
      <div className="slice-media-gallery__slider" ref={sliderRef}>
        <div className="slice-media-gallery__slider--wrapper">
          <Swiper
          
            slidesPerView={1}
            autoHeight={true}
            centeredSlides={true}
            initialSlide={0}
            onSlideChange={(swiper) => {

              var currentSlide = swiper.slides[swiper.activeIndex];
              if(currentSlide){
                var currentVideo = currentSlide.getElementsByClassName("swiper-video-to-pause");
                if(currentVideo){
                  setTimeout(refreshVideo, 100);
                  function refreshVideo() {
                    currentVideo[0].style.display = 'none';
                    setTimeout(refreshVideoBack, 50);
                  }
                  function refreshVideoBack() {
                    currentVideo[0].style.display = 'block';
                  }
                }
              }

              setLastIndex(swiper.realIndex);
              var prevSlide = swiper.slides[lastIndex];
              if(prevSlide){
                var video = prevSlide.getElementsByClassName("swiper-video-to-pause");
                if(video && video.length > 0) try{video[0].pause();}catch{}
              }
            }}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
            }}
            breakpoints={
              {
                // 768: {
                //   centeredSlides: lastItem ? true : false,
                //   spaceBetween: 48,
                // },
              }
            }
          >
            {items.map((item, idx) => {
              return (
                <SwiperSlide>
                  {item.isvideo || false ? (
                    <Video
                      forwardedRef={swiperRef}
                      index={idx}
                      className={`slice-media-gallery__slider--video ${
                        lastIndex === idx ? "active" : ""
                      }`}
                      {...item}
                      key={`slice-media-gallery-item-${idx}`}
                      current={lastIndex === idx}
                    />
                  ) : (
                    <Image
                      className={`slice-media-gallery__slider--image ${
                        lastIndex === idx ? "active" : ""
                      }`}
                      {...item}
                      key={`slice-media-gallery-item-${idx}`}
                      current={lastIndex === idx}
                    />
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {items.length > 1 ? <div className="swiper-pagination"></div> : null}
      </div>
    </WrapperElement>
  );
};

const Video = ({ className, embedvideo, localvideo, current, image, forwardedRef, index }) => {
  const isLocalVideo = localvideo.url ? localvideo.url : false;
  let provider = false;

  if (!isLocalVideo) {
    provider =
      embedvideo && embedvideo.provider_url.includes("youtube")
        ? "youtube"
        : "vimeo";
  }

  const playPauseVideo = useCallback(
    async (type) => {
      if (typeof window === "undefined" || !window.document) return null;
      if (!(window.player && Object.keys(window.player).length > 0))
        return null;

      const currentPlayer = window.player[embedvideo.prismicId];
      if (currentPlayer) {
        if (
          provider === "youtube" &&
          currentPlayer.hasOwnProperty("getPlayerState")
        ) {
          currentPlayer.hideVideoInfo();
          currentPlayer.getPlayerState() === 1 || type === "pause"
            ? currentPlayer.pauseVideo()
            : currentPlayer.playVideo();
        } else if (provider === "vimeo") {
          const paused = await currentPlayer.getPaused();
          !paused || type === "pause"
            ? currentPlayer.pause()
            : currentPlayer.play();
        }
      }
    },
    [embedvideo?.prismicId, provider]
  );

  useEffect(() => {
    if (!current && !isLocalVideo) {
      playPauseVideo("pause");
    }
  }, [current, isLocalVideo, playPauseVideo]);

  return isLocalVideo ? (
    <div className={className}>
      <video className="swiper-video-to-pause" src={isLocalVideo} poster={image?.url} controls={true} playsInline={true} onCanPlay={() => {if(index === 0){ forwardedRef.current.updateAutoHeight()}}} onLoadedData={() => {
        if(index === 0){ forwardedRef.current.updateAutoHeight()}
      }}>
        <source src={isLocalVideo}></source>
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
  ) : (
    <div className={className}>
      <div
        id={embedvideo.prismicId}
        className={`swiper-video-to-pause iframe iframe-${
          embedvideo.provider_url.includes("youtube") ? "youtube" : "vimeo"
        }`}
        data-url={embedvideo.embed_url}
      ></div>
      <div
        id={`overlay-${embedvideo.prismicId}`}
        style={{
          backgroundImage: `url(${
            image?.url ? image.url : "https://via.placeholder.com/1920x1080"
          })`,
        }}
        className="iframe-overlay active"
        onClick={(e) => {
          e.preventDefault();
          playPauseVideo();
        }}
      >
        <img src={playButton} alt=""></img>
      </div>
    </div>
  );
};

const Image = ({ className, image }) => {
  if (!image.url) return null;
  return (
    <div className={className}>
      <img src={image.url} alt="" />
    </div>
  );
};

const WrapperElement = ({ className, noSection, children }) => {
  return noSection ? (
    <div className={className}>{children}</div>
  ) : (
    <section className={className}>{children}</section>
  );
};

export const query = graphql`
  fragment PrismicProjectDataMediaGallery on PrismicProjectDataBody1MediaGallery {
    items {
      isvideo
      image {
        url
      }
      embedvideo {
        html
        embed_url
        prismicId
        provider_url
      }
      localvideo {
        url
      }
    }
  }
`;
